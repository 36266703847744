<template>
    <div class="footer">
        <div class="footer-wrapper">
            <div class="footer-left">
                <div class="cur-title">NEWSLETTER</div>
                <input class="form-input" v-model="content" placeholder="" />
                <div class="sub-btn">SUBSCRIBE</div>
                <div class="partner-title">JOIN OUR COMMUNITY</div>
                <div class="partner-list">
                    <img v-for="(item,index) in contactList" :key="index" class="partner-item" :src="item.logo" @click="toUrl(item.url)" />
                </div>
            </div>
            <div class="link-box" style="padding-left: 3rem;">
                <div class="label">COMPANY INFO</div>
                <div v-for="(item,index) in linkList1" :key="index" class="link-item" @click="toPath('/article', { title: item.title, slug: item.slug })">
                    {{item.title}}
                </div>
            </div>
            <div class="link-box">
                <div class="label">PAYMENT & SHIPPING</div>
                <div v-for="(item,index) in linkList2" :key="index" class="link-item" @click="toPath('/article', { title: item.title, slug: item.slug })">
                    {{item.title}}
                </div>
            </div>
            <div class="link-box">
                <div class="label">COMPANY POLICIES</div>
                <div v-for="(item,index) in linkList3" :key="index" class="link-item" @click="toPath('/article', { title: item.title, slug: item.slug })">
                    {{item.title}}
                </div>
            </div>
        </div>
        <div class="copyright">Copyright © 2021-2029 All Rights Reserved. POWERED BY SHOPUUE</div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                content: '',
                linkList1: [
                    {
                        title: 'About Us',
                        slug: 'info1'
                    },
                    {
                        title: 'Why Buy From Us',
                        slug: 'info2'
                    },
                    {
                        title: 'Terms & Conditions',
                        slug: 'info3'
                    },
                    {
                        title: 'Privacy Policy',
                        slug: 'info4'
                    }
                ],
                linkList2: [
                    {
                        title: '100% Guarantee',
                        slug: 'info5'
                    },
                    {
                        title: 'Payment Methods',
                        slug: 'info6'
                    },
                    {
                        title: 'Shipping Methods',
                        slug: 'info7'
                    }
                ],
                linkList3: [
                    {
                        title: 'Return Policy',
                        slug: 'info8'
                    },
                    {
                        title: 'Privacy Policy',
                        slug: 'info9'
                    },
                    {
                        title: 'Terms of Use',
                        slug: 'info10'
                    }
                ]
            }
        },
        created() {
            this.$store.dispatch('getContactList')
        },
        computed: {
            contactList() {
                return this.$store.state.contactList
            }
        },
        methods: {
            toUrl(url) {
                window.open(url)
            },
            
            toPath(path,query) {
                this.$router.push({
                    path,
                    query
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .footer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: $footer-height;
            box-sizing: border-box;
            padding: 5rem $box-padding 3rem;
            border-top: 1px solid #e9e9e9;
            background-color: #FFFFFF;
            
            .footer-wrapper {
                display: flex;
                width: 100%;
                max-width: $max-width;
                margin: 0 auto;
                
                .footer-left {
                    width: 28%;
                    
                    .cur-title {
                        width: 100%;
                        margin-bottom: 1.6rem;
                        font-size: 1.6rem;
                        color: #333333;
                        word-break: break-all;
                    }
                    
                    .form-input {
                        width: 100%;
                        height: 5rem;
                        box-sizing: border-box;
                        padding: 0 1.5rem;
                        margin-bottom: 1rem;
                        border: none;
                        outline: none;
                        font-size: 1.5rem;
                        color: #333333;
                        background-color: #f6f6f8;
                    }
                    
                    .sub-btn {
                        width: 100%;
                        height: 5rem;
                        line-height: 5rem;
                        box-sizing: border-box;
                        font-size: 1.4rem;
                        font-weight: bold;
                        color: #FFFFFF;
                        text-align: center;
                        background-color: #333333;
                        cursor: pointer;
                    }
                    
                    .partner-title {
                        width: 100%;
                        margin: 3rem 0 1rem;
                        font-size: 1.6rem;
                        color: #333333;
                        word-break: break-all;
                    }
                    
                    .partner-list {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        
                        .partner-item {
                            min-width: 4.4rem;
                            max-width: 4.4rem;
                            height: 4.4rem;
                            margin: 0 1rem 1rem 0;
                            cursor: pointer;
                        }
                    }
                }
                
                .link-box {
                    width: 18%;
                    padding-left: 2rem;
                    box-sizing: border-box;
                    color: #333333;
                    
                    .label {
                        width: 100%;
                        margin-bottom: 1.6rem;
                        font-size: 1.6rem;
                        word-wrap: break-word;
                    }
                    
                    .link-item {
                        width: 100%;
                        font-size: 1.4rem;
                        word-wrap: break-word;
                        cursor: pointer;
                        
                        &:hover {
                            color: #d1466e;
                            text-decoration: underline;
                        }
                        
                        &:not(:last-child) {
                            margin-bottom: 1.6rem;
                        }
                    }
                }
            }
            
            .copyright {
                width: 100%;
                max-width: $max-width;
                margin: 0 auto;
                font-size: 1.4rem;
                color: #333333;
                text-align: center;
                word-wrap: break-word;
            }
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .footer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            box-sizing: border-box;
            padding: 3rem $h5-box-padding;
            border-top: 1px solid #e9e9e9;
            background-color: #FFFFFF;
            
            .footer-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                margin-bottom: 3rem;
                
                .footer-left {
                    width: 100%;
                    
                    .cur-title {
                        width: 100%;
                        margin-bottom: 1.6rem;
                        font-size: 1.6rem;
                        color: #333333;
                        word-break: break-all;
                    }
                    
                    .form-input {
                        width: 100%;
                        height: 5rem;
                        box-sizing: border-box;
                        padding: 0 1.5rem;
                        margin-bottom: 1rem;
                        border: none;
                        outline: none;
                        font-size: 1.5rem;
                        color: #333333;
                        background-color: #f6f6f8;
                    }
                    
                    .sub-btn {
                        width: 100%;
                        height: 5rem;
                        line-height: 5rem;
                        box-sizing: border-box;
                        font-size: 1.4rem;
                        font-weight: bold;
                        color: #FFFFFF;
                        text-align: center;
                        background-color: #333333;
                        cursor: pointer;
                    }
                    
                    .partner-title {
                        width: 100%;
                        margin: 3rem 0 1rem;
                        font-size: 1.6rem;
                        color: #333333;
                        word-break: break-all;
                    }
                    
                    .partner-list {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        
                        .partner-item {
                            min-width: 4.4rem;
                            max-width: 4.4rem;
                            height: 4.4rem;
                            margin: 0 1rem 1rem 0;
                            cursor: pointer;
                        }
                    }
                }
                
                .link-box {
                    width: 100%;
                    color: #333333;
                    padding-left: 0 !important;
                    margin-top: 3rem;
                    
                    .label {
                        width: 100%;
                        margin-bottom: 1.6rem;
                        font-size: 1.6rem;
                        word-wrap: break-word;
                    }
                    
                    .link-item {
                        width: 100%;
                        font-size: 1.4rem;
                        word-wrap: break-word;
                        cursor: pointer;
                        
                        &:hover {
                            color: #d1466e;
                            text-decoration: underline;
                        }
                        
                        &:not(:last-child) {
                            margin-bottom: 1.6rem;
                        }
                    }
                }
            }
            
            .copyright {
                width: 100%;
                font-size: 1.4rem;
                color: #333333;
                text-align: center;
                word-wrap: break-word;
            }
        }
    }
</style>